import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "../../../Services/ApiServices/AuthService";
import { ProfileContext } from "../../../Services/ProfileProvider";
import { PasswordModal } from "../../../Services/PasswordModal";
import Swal from "sweetalert2";
import SpinLoader from "../../CoustamComponent/SpinLoader";

const Signup = () => {
    const ref = window.location.href.split("=")[1];

    const [showPass, setshowPass] = useState(false);
    const [showPass2, setshowPass2] = useState(false);
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [isOtpSentMob, setIsOtpSentMob] = useState(false);
    const [countdownMob, setCountdownMob] = useState(0);
    const { setLoginDetails } = useContext(ProfileContext);
    const [isActiveModel, setIsActiveModel] = useState(false);
    const [isPasswordModalOpen, setPasswordModalOpen] = useState(false);
    const [emailError, setEmailError] = useState();
    const [mobileError, setMobileError] = useState();
    const [passwordError, setPasswordError] = useState();
    const [showReferInput, setShowReferInput] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingModel, setIsLoadingModel] = useState(false);
    const [refferMessage, setRefferMessage] = useState("");
    const [validRefferCode, setValidRefferCode] = useState(false);
    const [showMobileOtp, setShowMobileOtp] = useState(false);
    const [isDisableInput, setIsDisableInput] = useState(true);
    const [responseResult, setResponseResult] = useState("");
    const [responseResultModel, setResponseResultModel] = useState("");
    const [isLoadingEmail, setIsLoadingEmail] = useState(false);
    const [isLoadingMobile, setIsLoadingMobile] = useState(false);
    const [responseResultSuccess, setResponseResultSuccess] = useState("");

    const navigate = useNavigate();

    const [conditions, setConditions] = useState({
        hasUpperCase: false,
        hasLowerCase: false,
        hasNumber: false,
        hasSpecialChar: false,
        passLength: false
    });

    const [formData, setFormData] = useState(
        {
            emailId: "",
            emailOtp: "",
            mobileNumber: "",
            mobileOtp: "",
            password: "",
            confirmPassword: "",
            countryCode: "+91",
            referral_code: ref || '',
        }
    );

    const handleInput = (event) => {
        const { name, value } = event.target;
        if ((name === "emailOtp" || name === "mobileOtp") && value.length <= 6) {
            setFormData({ ...formData, [name]: value });
        }
        else if (name === "mobileNumber") {
            if (value.length <= 10) {
                if (value === "" || !/^[0-6]/.test(value)) {
                    setFormData({ ...formData, [name]: value });
                    if (/* /^5\d{10}$/.test(value) *//^\d{10}$/.test(value)) {
                        handleCheckEmailPhone(value, "Phone");
                    } else {
                        setCountdownMob(0);
                        setMobileError("");
                    }
                } else {
                    setMobileError("Enter Valid Mobile Number");
                }
            }
        }
        else if (name === "emailId") {
            setFormData({ ...formData, [name]: value });
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
            if (emailPattern.test(value)) {
                handleCheckEmailPhone(value, "email");
            } else {
                setCountdown(0);
                setEmailError("");
            }
        }

        else if (name === "password") {
            const value = event.target.value;
            const hasUpperCase = /[A-Z]/.test(value);
            const hasLowerCase = /[a-z]/.test(value);
            const hasNumber = /\d/.test(value);
            const hasSpecialChar = /[@.#$!%*?&^]/.test(value);
            const passLength = value.length >= 8;

            setFormData({ ...formData, password: value });
            setConditions({ hasUpperCase, hasLowerCase, hasNumber, hasSpecialChar, passLength });
        }

        else if (name !== "emailOtp" && name !== "mobileOtp" && name !== "mobileNumber" && name !== "emailId") {
            setFormData({ ...formData, [name]: value });
        }
        setResponseResult("");
        setResponseResultModel("");
        setResponseResultSuccess("");
    };

    const handleCheckEmailPhone = async (signId, type) => {
        const result = await AuthService.checkEmailPhone(signId, type.toLowerCase());
        if (result?.success) {
            if (type.toLowerCase() === 'email') {
                setEmailError("");
            } else if (type.toLowerCase() === 'phone') {
                setMobileError("");
            }
        } else {
            if (type.toLowerCase() === 'email') {
                setEmailError(result?.message);
            } else if (type.toLowerCase() === 'phone') {
                setMobileError(result?.message);
            }
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { emailId, mobileNumber, password, confirmPassword } = formData;
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(emailId)) {
            setEmailError("Please enter a valid email address");
            return
        }
        if (mobileNumber?.length < 10) {
            setMobileError("Please enter a valid mobile number");
            return
        }
        if (emailError || mobileError || password !== confirmPassword) {
            if (emailError) {
                setEmailError(emailError);
            }
            if (mobileError) {
                setMobileError(mobileError);
            }
            if (password !== confirmPassword) {
                setPasswordError('Passwords do not match.');
            }
            return;
        }
        const passwordValid = conditions?.hasUpperCase &&
            conditions?.hasLowerCase &&
            conditions?.hasNumber &&
            conditions?.hasSpecialChar &&
            conditions?.passLength;

        if (!passwordValid) {
            setPasswordModalOpen(true);
            return;
        }
        if (!conditions?.hasUpperCase || !conditions?.hasLowerCase || !conditions?.hasNumber || !conditions?.hasSpecialChar || !conditions?.passLength) {
            setPasswordModalOpen(true);
            return
        }
        await Promise.all([
            handleSendOtp(emailId, "verification", "emailtype"),
        ]);
    };

    const handleSendOtp = async (signId, type, inputType) => {
        try {
            setIsLoading(true);
            const result = await AuthService.getOtp(signId, type);
            if (result?.success) {
                setIsActiveModel(true);
                setIsLoading(false);
                if (inputType === "emailtype") {
                    setIsOtpSent(true);
                    setCountdown(60);
                    setShowMobileOtp(true);
                    setResponseResult("");
                    setResponseResultSuccess(result?.message);
                } else if (inputType === "mobiletype") {
                    setIsOtpSentMob(true);
                    setCountdownMob(60);
                    setResponseResult("");
                    setResponseResultSuccess(result?.message);
                }
            } else {
                setIsLoading(false);
                setResponseResult(result);
                setResponseResultSuccess("");
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error?.message);
            setResponseResultSuccess("");
        }
    };


    const handleSignup = async (event) => {
        event.preventDefault();
        const { emailOtp, mobileOtp } = formData;
        if (!emailOtp) {
            setResponseResultModel("Please enter Email otp");
            return
        } if (!mobileOtp) {
            setResponseResultModel("Please enter Mobile otp");
            return
        }
        setIsLoadingModel(true);
        try {
            const result = await AuthService.register(formData);
            if (result?.success) {
                setIsLoadingModel(false);
                localStorage.setItem("token", result.data.token);
                localStorage.setItem("email", result.data.email);
                localStorage.setItem("user_id", result.data.user_id);
                setLoginDetails(result.data);
                setIsActiveModel(false);
                showAlert(result)
            } else {
                setIsLoadingModel(false);
                setResponseResultModel(result);
            }
        } catch (error) {
            setIsLoadingModel(false);
            setResponseResultModel(error);
        }
    };

    const showAlert = (result) => {
        let newData = result;
        Swal.fire({
            title: "Welcome to Crypto India",
            text: "Account Created Successfully",
            icon: "success",
            showCancelButton: true,
            confirmButtonColor: "#11a004",
            cancelButtonColor: "#e7e7e7",
            confirmButtonText: "Complete KYC",
            cancelButtonText: "Skip for now"
        }).then((swalResult) => {
            localStorage.setItem("token", newData.data.token);
            localStorage.setItem("email", newData.data.email);
            localStorage.setItem("user_id", newData.data.user_id);
            setLoginDetails(newData.data);
            if (swalResult.isConfirmed) {
                navigate("/verify_kyc");
            } else if (swalResult.isDismissed) {
                navigate("/dashboard");
            }
        });
    };


    const handleGetOtp = async (signId, type, inputType) => {
        if (inputType === "emailtype") {
            setIsLoadingEmail(true);
        } else if (inputType === "mobiletype") {
            setIsLoadingMobile(true);
        }

        try {
            const result = await AuthService.getOtp(signId, type);

            if (result?.success) {
                if (inputType === "emailtype") {
                    setIsOtpSent(true);
                    setCountdown(60);
                    setResponseResult("");
                    setResponseResultModel("");
                    setIsLoadingEmail(false);
                    setResponseResultSuccess(result?.message);
                } else if (inputType === "mobiletype") {
                    setIsOtpSentMob(true);
                    setCountdownMob(60);
                    setIsDisableInput(false);
                    setResponseResult("");
                    setResponseResultModel("");
                    setIsLoadingMobile(false);
                    setResponseResultSuccess(result?.message);
                }
            } else {
                setResponseResult(result);
                setIsLoadingEmail(false);
                setIsLoadingMobile(false);
                setResponseResultSuccess("");
            }
        } catch (error) {
            setResponseResult(error);
            setIsLoadingEmail(false);
            setIsLoadingMobile(false);
            setResponseResultSuccess("");
        }
    };


    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);

    useEffect(() => {
        let timer;
        if (isOtpSentMob && countdownMob > 0) {
            timer = setInterval(() => {
                setCountdownMob((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdownMob === 0) {
            setIsOtpSentMob(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSentMob, countdownMob]);


    const handleResetModel = () => {
        setIsActiveModel(false);
        setIsOtpSent(false);
        setCountdown(0);
        setIsOtpSentMob(false);
        setCountdownMob(0);
        setIsDisableInput(true);
        setResponseResultModel("")
        setFormData(prevFormData => ({
            ...prevFormData,
            emailOtp: "",
            mobileOtp: "",
        }));
    }

    useEffect(() => {
        const ref = window.location.href.split("=")[1];
        if (ref) {
            validateReferralCode(ref);
            setFormData(prevState => ({
                ...prevState,
                referral_code: ref,
            }));
        }
    }, []);


    const handleReferralInputChange = async (e) => {
        const { name, value } = e.target;
        const formattedValue = value.toUpperCase().replace(/[^A-Z0-9]/g, "");
        setFormData(prevState => ({
            ...prevState,
            [name]: formattedValue,
        }));
        if (formattedValue.trim()) {
            validateReferralCode(formattedValue);
        } else {
            setRefferMessage("");
        }
    };


    const validateReferralCode = async (code) => {
        try {
            const result = await AuthService.refferResult(code);
            if (result?.success) {
                setValidRefferCode(true);
            } else {
                setRefferMessage("Invalid referral code.");
                setValidRefferCode(false);
            }
        } catch (error) {
            setRefferMessage("An error occurred while validating the referral code.");
        }
    };


    return (
        <main>
            <section className="section-padding login_bg login_sec">
                <div className="section-overlay"></div>
                <div className="container">
                    <div className="row gx-5 align-items-center">
                        <div className="col-xl-6 col-lg-6  col-12">
                            <div className="login_qr">
                                <h4 className="letter_space"> Sign up now & <br /> Lighten Your </h4>
                                <h4 className="letter_space text-warning">Cryptocurrencies Journey</h4>
                                <img src="/assets/images/signup_bg.svg" width="380" className="img-fluid mx-auto mt-4 d-none d-md-block"
                                    alt="signupImg" />
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-6 col-12 mx-auto">
                            <div className="custom-form ticket-form mb-5 mb-lg-0" >
                                <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg">
                                    <div border-radius="50% 200% 40% 80%" class="css-1ueqzyg"></div>
                                </div>
                                <div border-radius="50% 200% 40% 80%" class="css-10gqqxt"></div>
                                <h5 className="text-center mb-4 text-warning" > Sign Up to Crypto India </h5>
                                <div className="tab-content">
                                    <form className="tab-pane show active" onSubmit={handleSubmit}>
                                        <div className="card-body ">
                                            {responseResult && (
                                                <div className="fl_card mb-3 mtm_error mt-3 " >
                                                    <div className="fl_row gap-2" >
                                                        <i class="ri-error-warning-fill"></i>
                                                        <p className="mt-0">
                                                            {responseResult}
                                                        </p>
                                                    </div>
                                                </div>
                                            )}
                                            <div className="row gx-2 gy-3">
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group " >
                                                        <input type="email" name="emailId" id="email" pattern="[^ @]*@[^ @]*" className="form-control mb-0" placeholder="Enter email address" required autoComplete="emailId" value={formData.emailId} onChange={handleInput} />
                                                        <div className="in_error" >
                                                            {emailError && (
                                                                <small> {emailError} </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group  " >
                                                        <div className="mnum_input" >
                                                            <span className="cn_code" >IN +91 <em>|</em> </span>
                                                            <input type="number" name="mobileNumber" id="mobileNumber" min="10" className="form-control mb-0" placeholder="Enter mobile number" required autoComplete="mobileNumber" value={formData.mobileNumber} onChange={handleInput} />

                                                        </div>
                                                        <div className="in_error" >
                                                            {mobileError && (
                                                                <small> {mobileError} </small>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group" >
                                                        <div className=" field-otp-box">
                                                            <input type={showPass ? 'text' : "password"} name="password" id="password" className="form-control mb-0" placeholder="Enter password" required value={formData.password} onChange={handleInput}
                                                                onFocus={() => setPasswordModalOpen(true)}
                                                                onBlur={() => setPasswordModalOpen(false)}
                                                            />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass(!showPass) }}>
                                                                <i className={`ri-eye${showPass ? "-line" : "-off-line"}`} />
                                                            </button>
                                                            <PasswordModal isOpen={isPasswordModalOpen} conditions={conditions} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-group " >
                                                        <div className="field-otp-box">
                                                            <input type={showPass2 ? 'text' : "password"} name="confirmPassword" id="confirmPassword" className="form-control mb-0" placeholder="Enter confirm password" required value={formData.confirmPassword} onChange={handleInput} />
                                                            <button type="button" className="btn btn-icon btn_view_pass btn-white" onClick={() => { setshowPass2(!showPass2) }}>
                                                                <i className={`ri-eye${showPass2 ? "-line" : "-off-line"}`} />

                                                            </button>
                                                        </div>
                                                        {formData.confirmPassword && formData.password !== formData.confirmPassword &&
                                                            <div className="in_error" ><small>{passwordError || "Passwords does not match."}</small></div>}
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <label className=" mb-0 d-flex align-items-center cursor-pointer form-label reff_accordian">
                                                        <span onClick={() => setShowReferInput((prev) => !prev)}>
                                                            Referral Code (Optional)
                                                            <i className={`ri-arrow-${showReferInput || formData?.referral_code ? "up-s-fill" : "down-s-fill"}`} style={{ fontSize: "20px" }}></i>
                                                        </span>
                                                    </label>
                                                    <div className="form-group" >

                                                        <input type="text" name="referral_code" id="referral_code" className={`form-control mb-0 mt-2 ${showReferInput || formData?.referral_code ? "" : "d-none"}`} placeholder="Referral Code" autoComplete="referral_code" value={formData.referral_code} onChange={handleReferralInputChange} />
                                                    </div>
                                                    {refferMessage && <p className="text-danger mty-0 pt-1"> <small>{refferMessage}  </small> </p>}
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <div className="form-check termsConditions  ">
                                                        <input className="form-check-input cursor-pointer" type="checkbox" required />
                                                        <label className="form-check-label pt-1">
                                                            I agree to Crypto India {" "}
                                                            <Link className="btn-link" target="_blank" to="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf"> Terms and Use </Link>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div className="col-lg-12 col-md-12 col-12">
                                                    <button type="submit" className="form-control mt-3" disabled={ref && !validRefferCode}>
                                                        {isLoading ?
                                                            <SpinLoader />
                                                            : <span>Sign up</span>}
                                                    </button>
                                                </div>

                                            </div>
                                            <div className="registration__info"> <span></span> OR <span></span> </div>
                                            <div className="row g-1" >
                                                <div className="col-lg-6 col-md-12 col-12 text-center text-lg-start  ">
                                                    <p className="mb-0" >
                                                        Already have an account? <Link className="fw-bold text-warning text-underline" to="/login" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}> Login </Link>
                                                    </p>
                                                </div>
                                                <div className="col-lg-6 col-md-12 col-12 text-center text-lg-end  ">
                                                    <p className="mb-0" >
                                                        <Link className="fw-bold text-warning text-underline" to="/entity-signup"> Sign up as Entity  </Link>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {
                isActiveModel &&
                <div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog  modal-dialog-centered">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label"> OTP Verification </h5>
                                    <button type="button" className="btn-close" onClick={handleResetModel}></button>
                                </div>
                                <form onSubmit={handleSignup} >
                                    <div className="modal-body p-md-4 ">
                                        {responseResultModel && (
                                            <div className="fl_card mb-3 mtm_error mt-1 " >
                                                <div className="fl_row gap-2" >
                                                    <i class="ri-error-warning-fill"></i>
                                                    <p className="mt-0">
                                                        {responseResultModel}
                                                    </p>
                                                </div>
                                            </div>
                                        )}
                                        <div className="row justify-content-center">
                                            <div className="col-lg-12 ">
                                                <label className="form-label" >Enter Email OTP</label>
                                                <div className="field-otp-box">
                                                    <input type="number" name="emailOtp" id="email_otp" className="form-control" placeholder="Enter Email OTP" required value={formData.emailOtp} onChange={handleInput} maxLength={6} />
                                                    <button type="button" className="btn btn-xs custom-btn" onClick={() => { handleGetOtp(formData.emailId, 'signup', 'emailtype') }} disabled={isOtpSent}>


                                                        {isLoadingEmail ?
                                                            <SpinLoader />
                                                            : <span> <span>{isOtpSent ? `Resend in ${countdown}s` : 'GET OTP'}</span></span>}

                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                showMobileOtp && formData.emailOtp?.length === 6 &&
                                                <div className="col-lg-12 mt-3 ">
                                                    <label className="form-label" >Enter Mobile OTP</label>
                                                    <div className=" field-otp-box">
                                                        <input type="number" name="mobileOtp" id="mobileOtp" className="form-control" placeholder="Enter Mobile OTP" required value={formData.mobileOtp} onChange={handleInput} maxLength={6} disabled={isDisableInput} />
                                                        <button type="button" className="btn btn-xs custom-btn" onClick={() => { handleGetOtp(formData.mobileNumber, 'signup', 'mobiletype') }} disabled={isOtpSentMob}>
                                                            {isLoadingMobile ?
                                                                <SpinLoader />
                                                                : <span>{isOtpSentMob ? `Resend in ${countdownMob}s` : 'GET OTP'}</span>}
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="in_success" >
                                            {responseResultSuccess && (
                                                <small> {responseResultSuccess} </small>
                                            )}
                                        </div>
                                        <div className=" col-lg-12 col-12 mt-1 text-center mt-3">
                                            <p className="text-muted fw-normal" >
                                                <small  >By submitting, I agree to Crypto India Terms of use & Privacy policy </small>
                                            </p>
                                            <button type="submit" className="btn px-5 0 btn-warning" disabled={isDisableInput || formData.mobileOtp?.length !== 6}>
                                                {isLoadingModel ? <SpinLoader /> : <span>SUBMIT</span>}
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"></div>
                </div>
            }
        </main>
    )
}

export default Signup