
import html2pdf from 'html2pdf.js';
import moment from 'moment/moment';

export const generatePdfBuySell = async (item, userKycDetail, userBankDetil, userUpiDetails, adminAccountNumber, adminUpi) => {
        try {

        const htmlContent =
            `
             <div> 
             <style type="text/css">
                 @import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700);

                            body {
                                margin: 0;
                                padding: 0;
                                background: #e1e1e1;
                            }

                            div,
                            p,
                            a,
                            li,
                            td {
                                -webkit-text-size-adjust: none;
                            }

                            .ReadMsgBody {
                                width: 100%;
                                background-color: #ffffff;
                            }

                            .ExternalClass {
                                width: 100%;
                                background-color: #ffffff;
                            }

                            body {
                                width: 100%;
                                height: 100%;
                                background-color: #e1e1e1;
                                margin: 0;
                                padding: 0;
                                -webkit-font-smoothing: antialiased;
                            }

                            html {
                                width: 100%;
                            }

                            p {
                                padding: 0 !important;
                                margin-top: 0 !important;
                                margin-right: 0 !important;
                                margin-bottom: 0 !important;
                                margin-left: 0 !important;
                            }

                            .visibleMobile {
                                display: none;
                            }

                            .hiddenMobile {
                                display: block;
                            }

  
   
                        </style> 
                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#e1e1e1">
                    
                    <tr>
                        <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
                                <tr className="hiddenMobile">
                                    <td height="40"></td>
                                </tr>
                                <tr className="visibleMobile">
                                    <td height="30"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <table width="720" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <table width="360" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                            <tbody>
                                                                <tr>
                                                                    <td align="left"> <img src="/logo.svg" height="40" alt="logo"
                                                                        border="0" /></td>
                                                                </tr>
                                                                <tr className="hiddenMobile">
                                                                    <td height="40"></td>
                                                                </tr>
                                                                <tr className="visibleMobile">
                                                                    <td height="20"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style="font-size: 16px; color: #000; font-family: 'Open Sans', sans-serif; line-height: 22px; vertical-align: top; text-align: left;">
                                                                        Hello, ${userKycDetail?.pandetails?.name}.
                                                                        <br /> Thank you for your order.
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                        <table width="360" border="0" cellpadding="0" cellspacing="0" align="right" className="col">
                                                            <tbody>
                                                                <tr className="visibleMobile">
                                                                    <td height="20"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td height="5"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style="font-size: 15px;color:${item?.side === "BUY" ? "#47dd68;" : "#ee1414;"}letter-spacing: 0px;font-family: 'Open Sans', sans-serif;line-height: 1;vertical-align: top;text-align: right;font-weight: 600;">
                                                                        ${item?.side} Receipt
                                                                    </td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style=" display: block; margin-left: auto; margin-top:10px; height: 3px;width: 50px; background: 
                                                                        ${item?.side === "BUY" ? "#47dd68;" : "#ee1414;"}">

                                                                    </td>
                                                                </tr>
                                                                <tr className="hiddenMobile">
                                                                    <td height="50"></td>
                                                                </tr>
                                                                <tr className="visibleMobile">
                                                                    <td height="20"></td>
                                                                </tr>
                                                                <tr>
                                                                    <td
                                                                        style="font-size: 14px; color: #5b5b5b; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: right;">
                                                                        <small>Order Number: </small> #${item?._id?.slice(0, 5)}<br />
                                                                        <small> Order Date: <b>${moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}</b></small>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </td>
                    </tr>
                </table>

                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#e1e1e1">
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable"
                                    bgcolor="#ffffff">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="720" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <table width="360" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                                    <tbody>
                                                                        <tr className="hiddenMobile">
                                                                            <td height="40"></td>
                                                                        </tr> 
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 13px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 1; vertical-align: top; ">
                                                                                <strong> Trade with: </strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100%" height="10"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                <b> CRYPTO INDIA </b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                User Side: <b>MAKER_SELLER</b>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>

                                                                <table width="360" border="0" cellpadding="0" cellspacing="0" align="right" className="col">
                                                                    <tbody>
                                                                        <tr className="hiddenMobile">
                                                                            <td height="40"></td>
                                                                        </tr> 
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 13px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 1; vertical-align: top; ">
                                                                                <strong>Receipt Generated For: </strong>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td width="100%" height="10"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                <b>  ${userKycDetail?.pandetails?.name} </b>
                                                                            </td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                User Side: <b>TAKER_BUYER</b>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable"
                                    bgcolor="#ffffff">
                                    <tbody>
                                        <tr>
                                            <tr className="hiddenMobile">
                                                <td height="30"></td>
                                            </tr>
                                            <tr className="visibleMobile">
                                                <td height="20"></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <table width="720" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                                        <tbody>
                                                            <tr>
                                                                <th  height="20"
                                                                    style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; font-weight: normal; line-height: 1; vertical-align: middle; padding: 0 10px 15px 0;"
                                                                    width="52%" align="left; ">
                                                                    Order Details
                                                                </th>
                                                            </tr>
                                                            <tr>
                                                                <td height="5"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="1" style="background: #e4e4e4;" colspan="4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="0" colspan="4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000000;  line-height: 18px;  vertical-align: bottom; padding:8px 0;"
                                                                    className="article">
                                                                    Coin Pair
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>${item?.base_currency + "/" + item?.quote_currency}</small>
                                                                </td>
                                                            </tr>

                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="margin-top:10px; border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    Total Amount of ${item?.side === "BUY" ? item.quote_currency : item.base_currency}
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>
                                                                        ${item?.get_amount?.$numberDecimal}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    Price
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>
                                                                        ${item?.price?.$numberDecimal}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    Fees
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>
                                                                        ${item?.qbs_fees_amount?.$numberDecimal}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    TDS
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>
                                                                        ${item?.tds_amount?.$numberDecimal}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    <b>Total  ${item?.side === "BUY" ? item.base_currency : item.quote_currency}</b>
                                                                </td>
                                                                <td
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small> 
                                                                        <b>${item?.final_get_amount?.$numberDecimal}</b>
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    Order Created Date
                                                                </td>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;"> :
                                                                    <small>
                                                                        ${moment(item?.createdAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                            <tr>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #000;  line-height: 18px;  vertical-align: top; padding:8px 0;"
                                                                    className="article">
                                                                    Order Completed Date
                                                                </td>
                                                                <td height="20"
                                                                    style="font-size: 15px; font-family: 'Open Sans', sans-serif; color: #333333;  line-height: 18px;  vertical-align: top; padding:8px 0;">  :
                                                                    <small>
                                                                        ${moment(item?.updatedAt).format('MMMM Do YYYY, h:mm:ss a')}
                                                                    </small>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td height="10"></td>
                                                            </tr>

                                                            <tr>
                                                                <td height="1" colspan="4" style="border-bottom:1px solid #e4e4e4"></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td height="20"></td>
                                            </tr>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#e1e1e1">
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
                                    <tbody>
                                    
                                        <tr className="hiddenMobile">
                                            <td height="20"></td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <table width="720" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                                    <tbody>
                                                        <tr>
                                                            <td style="vertical-align: top; ">
                                                                <table width="360" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                                    <tbody>
                                                                    <tr>
                                                                    <td style="vertical-align: top; ">
                                                                        <table width="360" border="0" cellpadding="0" cellspacing="0" align="left" className="col">
                                                                        <tbody>
                                                                            <tr className="visibleMobile">
                                                                            <td height="30"></td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="font-size: 13px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 1; vertical-align: top; ">
                                                                                <strong> SENDER PAYMENT DETAILS </strong>
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td width="100%" height="10"></td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                Payment Type : <b> Deposit </b>
                                                                                <br>Payment Method : <b>${!item?.amount_deposited && item?.use_available ? "Wallet" : item?.type}</b>
                                                                                ${item?.amount_deposited && !item?.use_available ? `
                                                                                    <br /> Payment Details: <b>${item?.type == "UPI" ? userUpiDetails : userBankDetil}</b>
                                                                                    <br />Txn No: <b>${item?.utr_number}</b>
                                                                                ` : ''}
                                                                            </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        </table>
                                                                    </td>
                                                                    <td style="vertical-align: top; ">
                                                                        <table width="360" border="0" cellpadding="0" cellspacing="0" align="right" className="col">
                                                                        <tbody>
                                                                            <tr className="visibleMobile">
                                                                            <td height="30"></td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="font-size: 13px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 1; vertical-align: top; ">
                                                                                <strong> RECEIVER PAYMENT DETAILS </strong>
                                                                            </td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td width="100%" height="10"></td>
                                                                            </tr>
                                                                            <tr>
                                                                            <td
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 20px; vertical-align: top; ">
                                                                                Payment Method : <b>${!item?.amount_deposited && item?.use_available ? "Wallet" : item?.type}</b>

                                                                                ${item?.amount_deposited && !item?.use_available ? `
                                                                                    <br> Payment Details: <b>${item?.type == "UPI" ? adminUpi : adminAccountNumber}</b>
                                                                                ` : ''}
                                                                            </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        </table>
                                                                    </td>
                                                                    </tr>
                                                                </tbody>
                                                                </table>
                                                                <tr className="hiddenMobile">
                                                                    <td height="20"></td>
                                                                </tr>
                                                                <tr className="visibleMobile">
                                                                    <td height="20"></td>
                                                                </tr>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        
                                        <tr className="hiddenMobile">
                                            <td height="40"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#e1e1e1">
                    <tbody>
                        <tr>
                            <td>
                                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable"
                                    bgcolor="#ffffff">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table width="720" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <img src="/favicon.png" height="60" alt="logo" border="0" />
                                                            </td>
                                                            <td style="vertical-align: middle; ">
                                                                <table>
                                                                    <tbody>
                                                                        <tr className="visibleMobile">
                                                                            <td height="30"></td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td height="20"
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 12px; vertical-align: top; ">
                                                                                Digitally signed by: <b> Crypto India</b>
                                                                            </td>
                                                                        </tr>                                                                      
                                                                        <tr>
                                                                            <td height="20"
                                                                                style="font-size: 14px; font-family: 'Open Sans', sans-serif; color: #5b5b5b; line-height: 12px; vertical-align: top; ">
                                                                                Generated Time : <b> ${moment().format('MMMM Do YYYY, h:mm:ss a')} </b>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                        <tr className="hiddenMobile">
                                            <td height="40"></td>
                                        </tr>
                                        <tr className="visibleMobile">
                                            <td height="20"></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#e1e1e1">
                    <tr>
                        <td>
                            <table width="100%" border="0" cellpadding="0" cellspacing="0" align="center" className="fullTable" bgcolor="#ffffff">
                                <tr>
                                    <td>
                                        <table width="720" border="0" cellpadding="0" cellspacing="0" align="center" className="fullPadding">
                                            <tbody>
                                                <tr>
                                                    <td
                                                        style="font-size: 10px; color: #c4c4c4; font-family: 'Open Sans', sans-serif; line-height: 18px; vertical-align: top; text-align: left;">
                                                        This is a system generated receipt
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                                <tr className="spacer">
                                    <td height="50"></td>
                                </tr>
                            </table>
                        </td>
                    </tr> 
                </table>
            </div>

           `
        const element = document.createElement("div");
        element.innerHTML = htmlContent;
        await html2pdf()
            .from(element)
            .save(`Invoice.pdf`);
    } catch (error) {
        console.error('Error generating PDF:', error);
    }
};
