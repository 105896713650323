import React, { useContext, useEffect, useState } from "react";
import DashboardSidebar from "../../../../CoustamComponent/DashboardSidebar";
import { ProfileContext } from "../../../../../Services/ProfileProvider";
import Select from 'react-select';
import AuthService from "../../../../../Services/ApiServices/AuthService";
import { format } from 'date-fns';
import Swal from "sweetalert2";
import moment from "moment";
import SpinLoader from "../../../../CoustamComponent/SpinLoader";
import { Link } from "react-router-dom";

const LevelTwoKyc = () => {

    const { userDetails, handleUserProfile, userKycDetails, handleGetKycDetails, loading, setLoading } = useContext(ProfileContext);
    const [kycStatus, setKycStatus] = useState("");
    const [isPanValid, setIsPanValid] = useState(false);
    const [isAadharValid, setIsAadharValid] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingPan, setIsLoadingPan] = useState(false);
    const [aadharOtpDetails, setAadharOtpDetails] = useState("");
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [countdown, setCountdown] = useState(0);
    const [checkButton, setCheckButton] = useState(false);
    const [kycFormStep, setKycFormStep] = useState('');
    const [isActivePanAadhar, setIsActivePanAadhar] = useState(false);
    const [isActiveLiveness, setIsActiveLiveness] = useState(false);
    const [isActiveEducation, setIsActiveEducation] = useState(false);
    const [activeModeKycReject, setActiveModeKycReject] = useState(false);

    const [isActiveAadharOtpField, setIsActiveAadharOtpField] = useState(false);
    const transactionId = localStorage.getItem("user_id");
    const [stepRejectedRemark, setStepRejectedRemark] = useState([]);
    const [progressBar, setProgressBar] = useState([]);
    const [responseResult, setResponseResult] = useState("");
    const [formError, setFormError] = useState({});
    const [decelaration, setDecelaration] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const [userLatestPan, setUserLatestPan] = useState();
    const [successMessage, setSuccessMessage] = useState("");



    const maskName = (name) => {
        if (!name) return '';
        const words = name.trim().split(' ');
        const maskedWords = words.map((word) => {
            if (word.length <= 2) return word;
            const firstChar = word[0];
            const lastChar = word[word.length - 1];
            const maskedPart = '*'.repeat(word.length - 2);
            return `${firstChar}${maskedPart}${lastChar}`;
        });
        return maskedWords.join(' ');
    };

    const [formData, setFormData] = useState({
        panNumber: '',
        aadharNumber: '',
        aadharOtp: '',
        edituaction: '',
        maritalStatus: '',
        occupation: '',
        annualIncome: '',
        tradingTurnover: '',
    });

    useEffect(() => {
        if (userDetails) {
            setFormData(prevState => ({
                ...prevState,
                panNumber: userKycDetails?.pandetails?.pan || userLatestPan || '',
                edituaction: userKycDetails?.otherDetails?.education || '',
                maritalStatus: userKycDetails?.otherDetails?.marital_status || '',
                occupation: userKycDetails?.otherDetails?.occupation || '',
                annualIncome: userKycDetails?.otherDetails?.annual_income || '',
                tradingTurnover: userKycDetails?.otherDetails?.annual_turnover || '',
            }));
        }
        setKycStatus(userDetails?.user?.kycVerified);
    }, [userDetails, userKycDetails]);

    // const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     let formattedValue = value;

    //     if (name === 'panNumber') {
    //         formattedValue = formattedValue.trim().toUpperCase().replace(/\s/g, '');
    //         formattedValue = formattedValue.replace(/(.{5})(.{4})(.{1})/, '$1$2$3');
    //         const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    //         if (panRegex.test(formattedValue)) {
    //             setIsPanValid(true);
    //             setErrorMessage("");
    //         } else {
    //             if (formattedValue?.length === 10) {
    //                 setIsPanValid(false);
    //                 setErrorMessage("Enter a valid PAN Number");
    //             } else {
    //                 setErrorMessage("");
    //             }
    //         }
    //     } else {
    //         setErrorMessage("");
    //     }

    //     setFormData(prevState => ({
    //         ...prevState,
    //         [name]: formattedValue
    //     }));
    //     setResponseResult("");
    //     setSuccessMessage("");
    // };



    const handleInputChange = (e) => {
        const { name, value } = e.target;

        let formattedValue = value.toUpperCase().trim();
        if (name === "panNumber") {
            const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
            if (formattedValue === "") {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: formattedValue,
                }));
                setIsPanValid(false);
                setErrorMessage("");
                return;
            }
            if (/^[A-Z]{0,5}$/.test(formattedValue) || /^[A-Z]{5}[0-9]{0,4}$/.test(formattedValue) || /^[A-Z]{5}[0-9]{4}[A-Z]{0,1}$/.test(formattedValue)) {
                setFormData((prevState) => ({
                    ...prevState,
                    [name]: formattedValue,
                }));
                if (formattedValue.length === 10) {
                    if (panRegex.test(formattedValue)) {
                        setIsPanValid(true);
                        setErrorMessage("");
                    } else {
                        setIsPanValid(false);
                        // setErrorMessage("Enter a valid PAN Number");
                    }
                } else {
                    setIsPanValid(false);
                    setErrorMessage("");
                }
            } else {
                // setErrorMessage("Enter a valid PAN Number.");
                setIsPanValid(false);
            }
        } else {
            setFormData((prevState) => ({
                ...prevState,
                [name]: formattedValue,
            }));
            setErrorMessage("");
        }
        setResponseResult("");
        setSuccessMessage("");
    };

    // const handleAadharChange = (e) => {
    //     let input = e.target.value.replace(/\D/g, '');
    //     const regex = /^[2-9]{1}[0-9]{7}$/;
    //     const isValid = regex.test(input);
    //     setFormData({
    //         ...formData,
    //         aadharNumber: input,
    //     });
    //     setIsAadharValid(isValid);
    //     setIsOtpSent(false);
    //     setCountdown(0);
    //     setIsActiveAadharOtpField(false);
    //     setResponseResult("");
    // };

    const handleAadharChange = (e) => {
        let input = e.target.value.replace(/\D/g, '');
        const formattedInput = input.replace(/(\d{4})(?=\d)/g, '$1 ');
        setFormData({
            ...formData,
            aadharNumber: formattedInput,
        });
        setIsOtpSent(false);
        setCountdown(0);
        setIsActiveAadharOtpField(false);
        setResponseResult("");
    };

    const formatDate = (dateString) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        return format(date, 'dd-MM-yyyy');
    };

    useEffect(() => {
        let timer;
        if (isOtpSent && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsOtpSent(false);
        }
        return () => clearInterval(timer);
    }, [isOtpSent, countdown]);


    const customStyles = {
        option: (provided, state) => ({
            ...provided,
            backgroundColor: state.isSelected
                ? 'black'
                : state.isFocused
                    ? 'gainsboro'
                    : 'white',
            color: state.isSelected
                ? 'white'
                : state.isFocused
                    ? 'dark'
                    : 'black',
            fontSize: '14px',
            borderRadius: '4px',
            margin: '0px',
        }),
    };

    const is18OrAbove = (birthDate) => {
        const today = new Date();
        const birth = new Date(birthDate);
        let age = today.getFullYear() - birth.getFullYear();
        const monthDifference = today.getMonth() - birth.getMonth();
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birth.getDate())) {
            age--;
        }
        return age >= 18;
    };

    const handleuserStep = async () => {
        const result = await AuthService.getuserKycStep();
        if (result?.success) {
            setKycFormStep(result.data?.step || "step1");
            handleGetKycDetails();
            handleProgressbar();
        }
    };

    const handleUpdateKycStep = async (step) => {
        if (!userKycDetails?.pandetails?.aadhaarLinked) {
            setResponseResult("Your PAN is not linked with Aadhaar")
            return;
        } if (!is18OrAbove(userKycDetails?.pandetails?.dateOfBirth)) {
            setResponseResult("your Age is below 18 Year")
            return;
        }
        setIsLoading(true);
        try {
            const result = await AuthService.updateKycStepStatus(step);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
                handleuserStep();
                handleProgressbar();
            } else {
                setIsLoading(false);
                setResponseResult(result);
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const handleProgressbar = async () => {
        await AuthService.getCurrentKycStatus().then(async (result) => {
            if (result?.success) {
                setProgressBar(result?.data)
            }
        }
        );
    };
    useEffect(() => {
        handleuserStep();
    }, [])

    const handleSubmitData = async (item) => {
        setIsLoadingPan(true);
        setResponseResult("");
        try {
            const result = await AuthService.verifyPancard(item);
            if (result?.success) {
                setIsLoadingPan(false);
                setIsPanValid(false);
                handleAmlCheck(result?.data?.pan, result?.data?.name, result?.data?.dateOfBirth);
                handleProgressbar();
            } else {
                setIsLoadingPan(false);
                setResponseResult(result);

            }
        } catch (error) {
            setIsLoadingPan(false);
        }
    };

    const handleAmlCheck = async (pan, name, dateOfBirth) => {
        try {
            await AuthService.getAmlCheckStatus(pan, name, formatDate(dateOfBirth)).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                } else {
                    setResponseResult(result);
                }
            });
        } catch (error) {
            setResponseResult(error);
        }
    };

    const handlegetOtpAAdhar = async (aadharNumber) => {
        setIsLoadingPan(true);
        setResponseResult("");
        try {
            const referenceId = Math.floor(100000 + Math.random() * 900000);
            const result = await AuthService.verifyAadharDocument(aadharNumber, referenceId);
            if (result?.success) {
                setAadharOtpDetails(result.data);
                setIsActiveAadharOtpField(true);
                setIsOtpSent(true);
                setCountdown(60);
                setIsLoadingPan(false);
                setSuccessMessage(result?.message);
            } else {
                setResponseResult(result);
                setIsOtpSent(false);
                setCountdown(0);
                setIsLoadingPan(false);
                setSuccessMessage("");
            }
        } catch (error) {
            setResponseResult(error);
            setSuccessMessage("");
            setIsLoadingPan(false);
        }
    };

    const handleVerifyAadhar = async (aadharOtp) => {
        setResponseResult("");
        setIsLoading(true);
        try {
            const result = await AuthService.getAadharDetails(aadharOtp, aadharOtpDetails?.transaction_id, aadharOtpDetails?.reference_id, formData?.panNumber);
            if (result?.success) {
                setIsLoading(false);
                handleGetKycDetails();
                handleProgressbar();
            } else {
                setIsLoading(false);
                setResponseResult(result?.message || JSON.stringify(result));
            }
        } catch (error) {
            setIsLoading(false);
            setResponseResult(error);
        }
    };

    const options = [
        { value: '', label: 'Please select one' },
        { value: 'Self Employed', label: 'Self Employed' },
        { value: 'Salaried Employed', label: 'Salaried Employed' },
        { value: 'House Wife', label: 'House Wife' },
        { value: 'Retired Person', label: 'Retired Person' },
    ];

    const optionsEducation = [
        { value: '', label: 'Please select one' },
        { value: 'Matriculation', label: 'Matriculation' },
        { value: 'Higher Secondary', label: 'Higher Secondary' },
        { value: 'Graduation', label: 'Graduation' },
        { value: 'Post Graduation', label: 'Post Graduation' },
        { value: 'Doctorate', label: 'Doctorate' },
    ];

    const optionsMaritalStatus = [
        { value: '', label: 'Please select one' },
        { value: 'Unmarried', label: 'Unmarried' },
        { value: 'Married', label: 'Married' },
    ];

    const optionsAnnualIncome = [
        { value: '', label: 'Please select one' },
        { value: '300000', label: '0-300000' },
        { value: '700000', label: '300001 - 700000' },
        { value: '1000000', label: '700001 - 1000000' },
        { value: '1200000', label: '1000001 - 1200000' },
        { value: '1500000', label: '1200001 - 1500000' },
        { value: '1500001', label: 'Above 15 Lakh' },
    ];

    const tradingTrunOver = [
        { value: '', label: 'Please select one' },
        { value: '100000000', label: '0-10 Crore' },
        { value: '500000000', label: '10 Crore - 50 Crore' },
        { value: '1000000000', label: '50 Crore - 100 Crore' },
        { value: '1000000001', label: 'Above 100 Crore' },
    ];

    const handleChange = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'occupation',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeEducation = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'edituaction',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeMarital = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'maritalStatus',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeAnualIncome = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'annualIncome',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleChangeTradingTurnover = (selectedOption) => {
        handleInputChange({
            target: {
                name: 'tradingTurnover',
                value: selectedOption.value,
            },
        });
        if (selectedOption.value) {
            setFormError({});
        }
    };

    const handleSubmitEducation = () => {
        setResponseResult("")
        if (!formData.edituaction) {
            setFormError({ edituaction: "Education is required." });
            return;
        }
        if (!formData.maritalStatus) {
            setFormError({ maritalStatus: "Marital status is required." });
            return;
        }
        if (!formData.occupation) {
            setFormError({ occupation: "Occupation is required." });
            return;
        }
        if (!formData.annualIncome) {
            setFormError({ annualIncome: "Annual income is required." });
            return;
        }
        if (!formData.tradingTurnover) {
            setFormError({ tradingTurnover: "Trading turnover is required." });
            return;
        }
        setFormError({});
        handleEducationOccupation();
    };


    const handleEducationOccupation = async () => {
        setResponseResult("")
        try {
            await AuthService.submitEducationOccupation(formData).then(async (result) => {
                if (result?.success) {
                    handleGetKycDetails();
                    handleProgressbar();
                    handleUpdateKycStep("step4");
                } else {
                    setResponseResult(result);
                }
            });
        } catch (error) {
            setResponseResult(error);
        }
    };


    const showAlert = () => {
        Swal.fire({
            title: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Successful" : "Kyc Pending",
            text: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Your Account has been verified successfully" : "Your Kyc is Under Review",
            icon: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "success" : "warning",
            // confirmButtonColor: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "#40ad15" : "#F0B90B",
            // confirmButtonText: userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Start Trading" : "Kyc In Review",
            // preConfirm: () => {
            //     if (userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high") {
            //         window.location.href = '/exchange/:pairName';
            //     }
            // },
            showCancelButton: false, // if you want to keep the cancel button
            showConfirmButton: false, // Hide the confirm button 
            timer: 2000,
            timerProgressBar: true,
            /*   willClose: () => {           
                  console.log("Alert closed after 10 seconds");
              } */
        });

    };



    const handleDeclarationSubmission = async () => {
        if (!checkButton) {
            setDecelaration(true);
            return;
        }
        try {
            const result = await AuthService.declaration();
            if (result?.success) {
                handleUserProfile();
                handleGetKycDetails();
                // showAlert();
            } else {
                setResponseResult(result);
            }
        } catch (error) {
            setResponseResult(error);
        }
    }

    const [currentDateTime, setCurrentDateTime] = useState(new Date());

    useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentDateTime(new Date());
        }, 1000);
        return () => clearInterval(intervalId);
    }, []);

    // const formattedDateTime = currentDateTime.toLocaleString();


    const formattedDateTime = format(currentDateTime, "do MMMM yyyy, h:mm:ss a");



    // Step2 Functions Start

    const handleLivenessResult = async () => {
        setLoading(true);
        try {
            const resultData = await AuthService.getLinkKycResult();
            if (resultData?.success) {
                handleFaceMatch(resultData?.data?.imageUrl, resultData?.data?._id);
            } else {
                setResponseResult(resultData);
                setLoading(false);
            }
        } catch (error) {
            setResponseResult(error);
            setLoading(false);
        }
    };

    const handleAuthLivenessSdk = async () => {
        setLoading(true);
        setResponseResult("");
        try {
            const resultData = await AuthService.authDataSdk();
            if (resultData?.success) {
                startOnboarding(resultData?.data?.result?.token);
            } else {
                setResponseResult("Failed to authenticate liveness SDK.");
            }
        } catch (error) {
            setResponseResult(error);
        }
    };

    const workflowId = "liveness";
    const startOnboarding = (sdkToken) => {
        if (window.HyperKycConfig && window.HyperKYCModule) {
            const hyperKycConfig = new window.HyperKycConfig(
                sdkToken,
                workflowId,
                transactionId,
            );
            window.HyperKYCModule.launch(hyperKycConfig, (sdkResult) => {
                handleLivenessResult(sdkResult);
            });
        } else {
            setResponseResult("HyperKYCModule or HyperKycConfig is not loaded.");
        }
    };

    const handleFaceMatch = async (imageLive, id) => {
        setLoading(true);
        await AuthService.getFaceMatchData(imageLive, userKycDetails?.adhaardetails?.adhaar_image, id).then(async (result) => {
            if (result?.success) {
                handleGetKycDetails();
                handleProgressbar();
            } else {
                setResponseResult(result)
                handleGetKycDetails();
            }
        });
    };

    const handleStepRejectedRemark = async () => {
        await AuthService.getStepRejectedRemark(!userKycDetails?.pandetails ? "all" : kycFormStep).then(async (result) => {
            if (result?.success && Array.isArray(result?.data)) {
                setStepRejectedRemark(result.data);
                setActiveModeKycReject(true);
            } else {
                setStepRejectedRemark([]);
                setActiveModeKycReject(false);
            }
        });
    };


    const handleUserLatestPan = async () => {
        await AuthService.getlatestPan().then(async (result) => {
            if (result?.success) {
                setUserLatestPan(result.data);
                setIsPanValid(true);
            }
        });
    };

    useEffect(() => {
        if (kycStatus === 3 || kycStatus === 4) {
            handleStepRejectedRemark()
        }
    }, [kycStatus]);

    useEffect(() => {
        if (kycStatus === 4) {
            handleUserLatestPan()
        }
    }, [kycStatus]);

    const filteredRemarks = stepRejectedRemark?.filter(item => item?.step === "reject_all")[0];
    const filteredRemarksRekyc = stepRejectedRemark?.filter(item => item?.step === "re_kyc")[0];
    const step1Remarks = stepRejectedRemark?.filter(item => item?.step === "step1")[0];
    const step2Remarks = stepRejectedRemark?.filter(item => item?.step === "step2")[0];
    const step3Remarks = stepRejectedRemark?.filter(item => item?.step === "step3")[0];

    console.log(formData.panNumber?.length, "!== 10 || !isPanValid");
    console.log(isPanValid, "isPanValid");



    return (
        <main>
            {
                kycStatus == 0 || kycStatus == 3 || kycStatus == 4 ?
                    <div className="pager_wrapper feature_bg overflow-hidden">
                        <div className="kycp_wrapper">
                            <section className="d_page ">
                                <div className="container">
                                    <div className="dd_title d-block text-center">
                                        <h3 className="text-warning">KYC Verification</h3>
                                        <p className="mb-0" >{kycStatus == 0 ? 'Complete your KYC in just 4 steps' : ""}</p>
                                    </div>
                                    <div className="mt_form">
                                        <ul id="progressbar">
                                            <ul id="progressbar">
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step1" ? "active" : ""}  ${progressBar?.[0]?.status === "complete" ? "success" : progressBar?.[0]?.status === "pending" ? "warning" : progressBar?.[0]?.status === "rejected" ? "danger" : progressBar?.[0]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step1"
                                                    onClick={() => setIsActivePanAadhar(!!userKycDetails?.pandetails)}>
                                                    <strong>Proof of Identification</strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step2" ? "active" : ""}  ${progressBar?.[1]?.status === "complete" ? "success" : progressBar?.[1]?.status === "pending" ? "warning" : progressBar?.[1]?.status === "rejected" ? "danger" : progressBar?.[1]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step2"
                                                    onClick={() => setIsActiveLiveness(!!userKycDetails?.facematchdetails && !!userKycDetails?.livenessdetails)}>
                                                    <strong> Account Limits </strong>
                                                </li>
                                                <li
                                                    className={`cursor-pointer ${kycFormStep === "step3" ? "active" : ""}  ${progressBar?.[2]?.status === "complete" ? "success" : progressBar?.[2]?.status === "pending" ? "warning" : progressBar?.[2]?.status === "rejected" ? "danger" : progressBar?.[2]?.status === "not-submitted" ? "current" : ""}`}
                                                    id="step3"
                                                    onClick={() => setIsActiveEducation(!!userKycDetails?.otherDetails)}>
                                                    <strong>Declaration and Submission</strong>
                                                </li>
                                            </ul>
                                        </ul>
                                        <div className="row justify-content-center" >
                                            <div className="col-xl-8 multistep_form ">
                                                {
                                                    kycFormStep === "step2" &&
                                                    <fieldset className="current">
                                                        {
                                                            step1Remarks && !userKycDetails?.pandetails &&
                                                            <div className="fl_card" >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <div>
                                                                        <h6> Verification Failed </h6>
                                                                        <p className=" ">
                                                                            {step1Remarks?.remarks}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-12 ">
                                                                    <div className="step_title" >
                                                                        <h4>
                                                                            Need to Upload Some Documents to Upgrade your account
                                                                        </h4>
                                                                        <p>
                                                                            Crypto India Required some valid documents to upgrade your account limits
                                                                        </p>
                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-2 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="field-box mb-3">
                                                                        <form

                                                                        >


                                                                            {/* new doc list */}

                                                                            <div className="dov_card" >
                                                                                <div className="row g-3">
                                                                                    <div className="col-lg-6" >
                                                                                        <div className="doc_row" >
                                                                                            <label htmlFor="coiFile"> ITR Declaration  </label>
                                                                                            <p> Format: PDF </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6" >
                                                                                        <input type="file" name="coiFile" className="form-control" id="coiFile" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dov_card" >
                                                                                <div className="row g-3">
                                                                                    <div className="col-lg-6" >
                                                                                        <div className="doc_row" >
                                                                                            <label htmlFor="moaFile"> Bank Statment (6 Months)  </label>
                                                                                            <p> Format: PDF </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6" >
                                                                                        <input type="file" className="form-control" id="moaFile" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dov_card" >
                                                                                <div className="row g-3">
                                                                                    <div className="col-lg-6" >
                                                                                        <div className="doc_row" >
                                                                                            <label htmlFor="aoaFile"> Salary Slip (6 Months)  </label>
                                                                                            <p> Format: PDF </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6" >
                                                                                        <input type="file" className="form-control" id="aoaFile" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="dov_card" >
                                                                                <div className="row g-3 align-items-center">
                                                                                    <div className="col-lg-6" >
                                                                                        <div className="doc_row" >
                                                                                            <label htmlFor="aoaFile"> Video KYC with PAN  </label>
                                                                                            <p>  Click to record live video with PAN Card </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="col-lg-6 text-end" >
                                                                                        <button className="btn btn-dark " > Record Video </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <button
                                                                                type="submit"
                                                                                className="form-control mt-4 no-border bg-warning btn-block w-100  "
                                                                            >
                                                                                Continue
                                                                            </button>
                                                                            {
                                                                                errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>
                                                                            }
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                }

                                                {
                                                    kycFormStep === "step1" &&
                                                    <fieldset className="current">
                                                        {
                                                            step1Remarks && !userKycDetails?.pandetails &&
                                                            <div className="fl_card" >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <div>
                                                                        <h6> Verification Failed </h6>
                                                                        <p className=" ">
                                                                            {step1Remarks?.remarks}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        }
                                                        <div className="form-card mt-0">
                                                            <div className="row justify-content-center ">
                                                                <div className="col-xl-12 ">
                                                                    <div className="step_title" >
                                                                        <h4>
                                                                            Set Your Account New Limits
                                                                        </h4>

                                                                    </div>
                                                                    {responseResult && (
                                                                        <div className="fl_card mb-2 mtm_error " >
                                                                            <div className="fl_row gap-2" >
                                                                                <i class="ri-error-warning-fill"></i>
                                                                                <p className="mt-0">
                                                                                    {responseResult}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    )}

                                                                    <div className="field-box mb-3">
                                                                        <form

                                                                        >


                                                                            {/* new doc list */}
                                                                            {/* <div className="dov_card" > */}

                                                                            <div className="row justify-content-center">
                                                                                <div className="col-lg-8 mb-3" >
                                                                                    <div className="field-box" >
                                                                                        <label for="entityName" class="form-label">   Desired Total (FY) Account Withdrawal Limit (INR) </label>
                                                                                        <input type="text" className="form-control" placeholder="Enter Amount (INR)" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-8 mb-3" >
                                                                                    <div className="field-box" >
                                                                                        <label for="entityName" class="form-label">  Desired Daily Withdrawal Limit (INR) </label>
                                                                                        <input type="text" className="form-control" placeholder="Enter Amount (INR)" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-8 mb-3" >
                                                                                    <div className="field-box" >
                                                                                        <label for="entityName" class="form-label">   Reason to Limit Enhancement </label>
                                                                                        <textarea className="form-control" row="4" ></textarea>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-8 " >
                                                                                    <button
                                                                                        type="submit"
                                                                                        className="form-control mt-4  no-border bg-warning btn-block w-100  "
                                                                                    >
                                                                                        Continue
                                                                                    </button>
                                                                                </div>

                                                                            </div> 

                                                                            {
                                                                                errorMessage && <div className="invalid-feedback d-block">{errorMessage}</div>
                                                                            }
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </fieldset>
                                                }

                                                {
                                                    kycFormStep === "step3" &&
                                                    <fieldset className="current">
                                                        <form className="form-card"
                                                            onSubmit={(e) => {
                                                                e.preventDefault();
                                                                handleDeclarationSubmission();
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="p_card mt-2 p-3">
                                                                    <h5>DECLARATION OF SOURCE OF FUNDS</h5>
                                                                    <ul className="desc mb-0">
                                                                        <p>Date: &nbsp; <b> {formattedDateTime} </b></p>
                                                                        <li className="mb-2 d-block">
                                                                            <b>[{userKycDetails?.pandetails?.name}]</b>, a citizen of India, holding Aadhaar Number <b>[{userKycDetails?.pandetails?.maskedAadhaarNumber}]</b> and PAN Card number <b>
                                                                                [{userKycDetails?.pandetails?.pan}]</b>, hereby declare and affirm that all funds used in my financial transactions, with CRYPTO INDIA (Incryp Blockchain Private Limited) in INR and any other crypto currency, are obtained from legitimate and legal sources. The funds are fully compliant with all applicable laws, regulations, and guidelines, including Anti-Money Laundering (AML) and Counter-Terrorist Financing (CTF) regulations.
                                                                        </li>
                                                                        <li className="mb-2">We further declare that:</li>

                                                                        <li className="mb-2 d-block">
                                                                            1. Legitimate Source: All funds used in our company’s financial operations, including the acquisition of crypto assets, are free from any association with illegal activities such as money laundering, tax evasion, or terrorist financing.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            2. Compliance with AML Regulations: Our company follows stringent internal controls and compliance measures, ensuring full adherence to AML, CTF, and Know Your Customer (KYC) guidelines as stipulated by regulatory authorities. We have systems in place to detect and prevent suspicious or unlawful financial activities
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            3. Due Diligence: Our company undertakes regular due diligence on all counterparties and customers to verify the legitimacy of funds, consistent with legal requirements, before entering any transaction involving crypto assets or traditional financial services.

                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            4. Ethical Standards: Our company upholds the highest standards of ethical conduct in all financial dealings, ensuring that funds used in all transactions are derived from legitimate business activities and are compliant with domestic and international regulations.
                                                                        </li>

                                                                        <li className="mb-2 d-block">
                                                                            5. Cooperation with Authorities: In the event of any suspicion, inquiry, or investigation into the source of our company’s funds, we commit to full cooperation with law enforcement agencies, financial institutions, and regulatory authorities. We are prepared to provide all necessary documentation and information to verify the source of funds when required.
                                                                        </li>
                                                                    </ul>
                                                                    <div className="avil_bal my-2 mb-1 " >
                                                                        <div className="form-check cursor-pointer  d-block" >
                                                                            <input className={`form-check-input cursor-pointer ${decelaration ? "is-invalid" : ""}`} type="checkbox" id="flexCheckDefault" defaultChecked={checkButton} value={checkButton} onChange={() => {
                                                                                setCheckButton((prev) => !prev);
                                                                                setDecelaration(false);
                                                                            }} required />

                                                                            <label className="form-check-label" label="flexCheckDefault">
                                                                                <b>   I understand and agree to the above declaration,   <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/terms_and_conditions.pdf" className="text-dark text-underline" target="_blank"> terms of use  </a>
                                                                                    &nbsp;  and <a href="https://cei-user-images.s3.amazonaws.com/crypto_india_documents/refund_policy.pdf" className="text-dark text-underline" target="_blank"> refund policy.  </a>
                                                                                    &nbsp;  </b>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row" >
                                                                <div className="col-md-6" >
                                                                    <div className="form-group mt-4 " >
                                                                        <button className="btn form-control btn-warning  btn-block w-100 " type="submit" > Submit </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </form>
                                                        {responseResult && (
                                                            <div className="fl_card " >
                                                                <div className="fl_row" >
                                                                    <i class="ri-close-circle-fill"></i>
                                                                    <p className="mt-0">
                                                                        {responseResult}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </fieldset>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </div>
                    </div>
                    :
                    <div className="page_wrapper">
                        <div className="sidebar">
                            <DashboardSidebar />
                        </div>
                        <div className="af_wrapper">
                            <section className="d_page ">
                                <div className="container">
                                    <div className="dd_title d-block text-center">
                                        <h3 className="text-warning">KYC Verification</h3>
                                    </div>
                                    <fieldset className="current row gy-4 justify-content-center">
                                        {/* level 2 kyc popup */}
                                        <div className=" col-xl-10 " >
                                            <div className="upgrr_card overflow-hidden d-none">
                                                <div class="points_wrapper points_wrapper_2">
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                </div>
                                                <div className="row align-items-end" >
                                                    <div className="col-xl-8" >
                                                        <div className="upd_title" >
                                                            <h3>
                                                                Account Upgrade Available
                                                            </h3>
                                                            <p>
                                                                Based on your recent account activity and good behavior, you are now eligible
                                                                to upgrade your account.
                                                            </p>
                                                            <ul>
                                                                <li> <i class="ri-check-fill"></i> Higher fiat (INR) account withdrawal limit</li>
                                                                <li> <i class="ri-check-fill"></i> Higher fiat (INR) daily withdrawal limit</li>
                                                                <li> <i class="ri-check-fill"></i> Higher fiat (INR) daily buy limit </li>
                                                                <li> <i class="ri-check-fill"></i> Benefits of lower trade fee</li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4" >
                                                        <button type="button" class=" custom_btn  ">
                                                            <div class="points_wrapper">
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                                <i class="point"></i>
                                                            </div>
                                                            <span class="inner"  >
                                                                Upgrade Account
                                                                <em>
                                                                    <svg
                                                                        class="icon"
                                                                        fill="none"
                                                                        stroke="currentColor"
                                                                        viewBox="0 0 24 24"
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        stroke-linecap="round"
                                                                        stroke-linejoin="round"
                                                                        stroke-width="2.5"   >
                                                                        <polyline points="13.18 1.37 13.18 9.64 21.45 9.64 10.82 22.63 10.82 14.36 2.55 14.36 13.18 1.37"  ></polyline>
                                                                    </svg>
                                                                </em>
                                                            </span >
                                                        </button>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className=" col-xl-10 " >
                                            <div className="upgrr_card overflow-hidden uprrr_kyc ">
                                                <div class="points_wrapper points_wrapper_2">
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                    <i class="point"></i>
                                                </div>
                                                <div className="upd_title" >
                                                    {kycStatus == 3 || kycStatus === 4 ?
                                                        <div className=" " >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3 className="text-danger" >
                                                                    <i class="ri-close-circle-fill me-2 fw-normal text-danger"></i>  Account verification Rejected
                                                                </h3>
                                                            </div>
                                                            <p>
                                                                {/* Check submitted information for accuracy and follow guidelines for resubmission or appeal. */}
                                                                Your account verification has been rejected. Please Check submitted information for accuracy and follow guidelines for resubmission or appeal.
                                                            </p>
                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {kycStatus == 1 &&
                                                        <div className=" " >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3 className="" >
                                                                    <i class="ri-error-warning-line me-2 fw-normal text-warning"></i>  Account verification Pending
                                                                </h3>
                                                                {/* <span> <small>  Kyc Submited on: </small>
                                                                    {
                                                                        userKycDetails?.kycdeclaration?.updatedAt ?
                                                                            moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                                                            : "--------"
                                                                    }
                                                                </span> */}
                                                            </div>
                                                            <p>
                                                                Your account verification is currently under review. Our team is carefully processing your information to ensure everything is in order. You will be notified via mail once the review is complete.
                                                            </p>
                                                        </div>
                                                    }
                                                    {kycStatus == 2 &&
                                                        <>
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3>
                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i>  Kyc Successfully Approved
                                                                </h3>
                                                                <span> <small> Approved on:  </small>
                                                                    {
                                                                        userKycDetails?.kycdeclaration?.updatedAt ?
                                                                            moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                                                            : "--------"
                                                                    }
                                                                </span>
                                                            </div>
                                                            <p>
                                                                Your verification has been successfully approved! You now have access to unlimited crypto deposits and withdrawals. Deposit your funds and start trading with ease today!
                                                            </p>

                                                        </>
                                                    }
                                                </div>
                                                <div className="row align-items-end" >
                                                    <div className="col-xl-8" >
                                                        <ul className="ur_pp ur_vefy" >
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Proof of Identification
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.pandetails ? "badge-success" : !userKycDetails?.pandetails ? "badge-danger" : ""}`}>

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.pandetails ? "ri-check-double-line me-1" : !userKycDetails?.pandetails ? "ri-close-circle-line me-1" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.pandetails ? "Verified" : !userKycDetails?.pandetails ? "Verification Failed!" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Check Liveness
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 || kycStatus === 4 ? "badge-danger" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "ri-check-double-line  me-1" : kycStatus === 1 ? "ri-error-warning-line  me-1" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line  me-1" : ""} ></i>


                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Verified" : kycStatus == 1 ? "Verification Pending" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Education & Occupation
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.otherDetails ? "badge-success" : !userKycDetails?.otherDetails ? "badge-warning bg-warning" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.otherDetails ? "ri-check-double-line  me-1" : !userKycDetails?.otherDetails ? "ri-error-warning-line" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.otherDetails ? "Verified" : !userKycDetails?.otherDetails ? "Verification Pending" : ""}
                                                                </span>
                                                            </li>
                                                            <li className="d-flex align-items-center justify-content-between">
                                                                Declaration and Submission
                                                                <span className={`ky_ badge ${kycStatus === 1 ? "badge-warning" : kycStatus === 2 ? "badge-success" : kycStatus === 3 ? "badge-danger" : kycStatus === 4 ? "badge-danger" : userKycDetails?.kycdeclaration ? "badge-success" : !userKycDetails?.kycdeclaration ? "badge-danger" : ""}`} >

                                                                    <i className={kycStatus === 1 ? "ri-error-warning-line me-1" : kycStatus === 2 ? "ri-check-double-line me-1" : kycStatus === 3 ? "ri-close-circle-line me-1" : kycStatus === 4 ? "ri-close-circle-line me-1" : userKycDetails?.kycdeclaration ? "ri-check-double-line  me-1" : !userKycDetails?.kycdeclaration ? "ri-close-circle-line  me-1" : ""} ></i>

                                                                    {kycStatus === 1 ? "Verification Pending" : kycStatus === 2 ? "Verified" : kycStatus === 3 ? "Verification Failed" : kycStatus === 4 ? "Verification Failed" : userKycDetails?.kycdeclaration ? "Verified" : !userKycDetails?.kycdeclaration ? "Verification Failed!" : ""}
                                                                </span>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    {kycStatus == 2 &&
                                                        <div className="col-xl-4" >
                                                            <Link to="/exchange/:pairName" class=" custom_btn   ">
                                                                <div class="points_wrapper">
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                    <i class="point"></i>
                                                                </div>
                                                                <span class="inner"  >
                                                                    Start Trading
                                                                    <em>
                                                                        <div className="arrow" ></div>
                                                                    </em>
                                                                </span >
                                                            </Link>

                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {/* Level 1 KYC Successcull popup  */}
                                        {/* <div className="col-xl-10">
                                            <div className="form-card kyc_card ">
                                                <div className="kyc_stst" >
                                                    <div className="kyc_row" >
                                                        <h5> Proof of Identification  </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : userKycDetails?.pandetails ? "badge-success" : !userKycDetails?.pandetails ? "badge-danger" : ""}`}>

                                                            <i className={kycStatus === 2 ? "ri-check-double-line me-1" : userKycDetails?.pandetails ? "ri-check-double-line" : !userKycDetails?.pandetails ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus === 2 ? "Verified" : userKycDetails?.pandetails ? "Verified" : !userKycDetails?.pandetails ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Check Liveness </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 || kycStatus === 4 ? "badge-danger" : ""}`} >

                                                            <i className={kycStatus === 2 ? "ri-check-double-line me-1" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "ri-check-double-line" : kycStatus === 1 ? "ri-error-warning-line" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus == 2 ? "Verified" : userKycDetails?.facematchdetails?.action === "pass" && userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "Verified" : kycStatus == 1 ? "Verification Pending" : kycStatus === 3 || kycStatus === 4 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Education & Occupation </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : userKycDetails?.otherDetails?.pep === false ? "badge-success" : userKycDetails?.otherDetails?.pep === true ? "badge-warning bg-warning" : kycStatus === 3 || kycStatus === 4 ? "badge-danger" : ""}`} >

                                                            <i className={kycStatus === 2 ? "ri-check-double-line  me-1" : userKycDetails?.otherDetails?.pep === false ? "ri-check-double-line" : userKycDetails?.otherDetails?.pep === true ? "ri-error-warning-line" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus === 2 ? "Verified" : userKycDetails?.otherDetails?.pep === false ? "Verified" : userKycDetails?.otherDetails?.pep === true ? "Verification Pending" : kycStatus === 3 || kycStatus === 4 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>
                                                    <div className="kyc_row" >
                                                        <h5> Declaration and Submission </h5>
                                                        <span className={`ky_ badge ${kycStatus === 2 ? "badge-success" : kycStatus === 1 ? "badge-warning bg-warning" : kycStatus === 3 || kycStatus === 4 ? "badge-danger" : ""}`} >

                                                            <i className={kycStatus === 2 ? "ri-check-double-line  me-1" : kycStatus === 1 ? "ri-error-warning-line" : kycStatus === 3 || kycStatus === 4 ? "ri-close-circle-line" : ""} ></i>

                                                            {kycStatus == 2 ? "Verified" : kycStatus == 1 ? "Verification Pending" : kycStatus === 3 || kycStatus === 4 ? "Verification Failed!" : ""}
                                                        </span>
                                                    </div>

                                                    {kycStatus == 3 || kycStatus === 4 ?
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-4" >
                                                                <p> Account verification request rejected...
                                                                    (Check submitted information for accuracy and follow guidelines for resubmission or appeal.)<br />
                                                                </p>
                                                            </div>
                                                        </div>
                                                        :
                                                        null
                                                    }

                                                    {kycStatus == 1 &&
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-1" >
                                                                <i className="ri-error-warning-line me-1 text-warning" style={{ fontSize: "30px" }}></i>
                                                                {
                                                                    userKycDetails?.kycdeclaration?.updatedAt &&
                                                                    <p className="mb-0">
                                                                        Kyc Submited : {moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')} </p>
                                                                }
                                                                <p className="text-warning mb-0">
                                                                    Your Kyc is Under Review </p>
                                                                <p >You will receive an email once the verification is complete.</p>
                                                            </div>
                                                        </div>
                                                    }

                                                    {kycStatus == 2 &&
                                                        <div className="kyc_row justify-content-center" >
                                                            <div className="text-center mt-1" >
                                                                <i className="ri-verified-badge-fill me-1 text-success" style={{ fontSize: "30px" }}></i>
                                                                <h6>
                                                                    <small>Kyc Successfully Approved on : </small>
                                                                </h6>
                                                                <p className="mb-0">
                                                                    <small> {
                                                                        userKycDetails?.kycdeclaration?.updatedAt ?
                                                                            moment(userKycDetails?.kycdeclaration?.updatedAt).format('Do MMMM YYYY, h:mm:ss a')
                                                                            : "--------"
                                                                    }</small>
                                                                </p>
                                                                <p className="mb-0 mt-2" >
                                                                    Enjoy your account with unlimited crypto deposits and withdrawals.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div> */}

                                        <div className=" col-xl-10 " >
                                            {/* level 2 verification pending */}
                                            <div className="upgrr_card overflow-hidden upgrr_card_ur d-none   ">
                                                <div className="row align-items-end" >
                                                    <div className="col-xl-12" >
                                                        <div className="upd_title" >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3>
                                                                    <i class="ri-error-warning-fill me-2 fw-normal text-warning"></i> Account upgrade Pending
                                                                </h3>
                                                                <span> <small> Applied on:  </small> 11 Jan 2025, 12:11 PM </span>
                                                            </div>
                                                            <p>
                                                                Your request to upgrade your account is still being processed. Please wait for confirmation or check for any required actions to complete the upgrade
                                                            </p>
                                                            <ul className="ur_pp" >
                                                                <li>  <span className="d-flex align-items-center justify-content-between" > <i class="ri-error-warning-line  text-warning  fw-normal  me-2" ></i>    Higher fiat (INR) account withdrawal limit</span> <b className="" > Pending </b>  </li>
                                                                <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line  text-warning   fw-normal me-2"></i>     Higher fiat (INR) daily withdrawal limit</span> <b className="" > Pending </b> </li>
                                                                <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line  text-warning  fw-normal me-2"></i>    Higher fiat (INR) daily buy limit </span><b className="" > Pending </b> </li>
                                                                <li className="no-border" > <span className="d-flex align-items-center justify-content-between"> <i class="ri-error-warning-line fw-normal text-warning  me-2"></i>  New trade fee </span><b className="" > Pending </b> </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            {/* level 2 kyc verification success  */}
                                            <div className="upgrr_card overflow-hidden  d-none ">
                                                <div className="row align-items-end" >
                                                    <div className="col-xl-12" >
                                                        <div className="upd_title" >
                                                            <div className="d-flex align-items-center justify-content-between" >
                                                                <h3>
                                                                    <i class="ri-verified-badge-fill me-2 fw-normal text-success"></i> Account Upgraded Successfully
                                                                </h3>
                                                                <span> <small> Approved on:  </small> 11 Jan 2025, 12:11 PM </span>
                                                            </div>
                                                            <p>
                                                                Your account has been successfully upgraded to the desired tier or plan. Enjoy the new features and benefits that come with your upgraded account
                                                            </p>
                                                            <ul className="ur_pp" >
                                                                <li>  <span className="d-flex align-items-center justify-content-between" > <i class="ri-check-fill   fw-normal  me-2" ></i>    New fiat (INR) account withdrawal limit</span> <b className="" >  000.000 </b>  </li>
                                                                <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill    fw-normal me-2"></i>     New fiat (INR) daily withdrawal limit</span> <b className="" >  000.000 </b> </li>
                                                                <li> <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill   fw-normal me-2"></i>    New fiat (INR) daily buy limit </span><b className="" > 000.000 </b> </li>
                                                                <li className="no-border" > <span className="d-flex align-items-center justify-content-between"> <i class="ri-check-fill me-2"></i>  New trade fee </span><b className="" > 00 </b> </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </fieldset>
                                </div>
                            </section>
                        </div>
                    </div>
            }


            {activeModeKycReject && !userKycDetails?.pandetails && (
                <div div className="modal-open-custom fadeInUp" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content">
                                <div className="modal-body fl_modal p-lg-5">
                                    <div className="row g-5 align-items-center" >
                                        <div className="col-lg-7" >
                                            <h5 className="text-danger" >KYC Verification Failed!</h5>
                                            <p>{filteredRemarks?.remarks}</p>
                                            <p>{filteredRemarksRekyc?.remarks}</p>
                                            <button className="btn btn-danger btn-md px-4 " onClick={() => setActiveModeKycReject(false)} > Submit KYC Again  </button>
                                            <button className="btn btn-link" onClick={() => setActiveModeKycReject(false)} > Skip For Now </button>
                                        </div>
                                        <div className="col-lg-5" >
                                            <img src="assets/images/kyc_failed.svg" className="img-fluid" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActivePanAadhar && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Pan Card & Aadhaar Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActivePanAadhar(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> User Name: </span>
                                                <span className="tt_normal">
                                                    {maskName(userKycDetails?.pandetails?.firstName)} {maskName(userKycDetails?.pandetails?.middleName)} {maskName(userKycDetails?.pandetails?.lastName)} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Pan Number: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.pandetails?.pan} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>

                                            {
                                                userKycDetails?.pandetails?.verified &&
                                                <>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Aadhaar Name: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.adhaardetails?.full_name} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                    <div className="tt_item no-border">
                                                        <span className="text-muted"> Aadhaar Number: </span>
                                                        <span className="tt_normal">
                                                            {userKycDetails?.pandetails?.maskedAadhaarNumber} &nbsp;
                                                            <i className="ri-verified-badge-fill text-success"></i>
                                                        </span>
                                                    </div>
                                                </>
                                            }

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActiveLiveness && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Liveness Image</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveLiveness(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"></span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.pandetails?.firstName} {userKycDetails?.pandetails?.middleName} {userKycDetails?.pandetails?.lastName} &nbsp;
                                                    <i className="ri-verified-badge-fill text-success"></i>
                                                </span>
                                            </div>
                                            <div className="web_cam_row"  >
                                                <div className={`web_cam_box ${userKycDetails?.facematchdetails?.facematch_value === "yes" &&
                                                    userKycDetails?.facematchdetails?.facematch_confidence === "high" ? "success" : "failed"}`} >
                                                    <img src={userKycDetails?.livenessdetails?.imageUrl} alt="Liveness Check" className="img-fliud" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

            {isActiveEducation && (
                <div div className="modal-open-custom" >
                    <div className="modal show d-block" >
                        <div className="modal-dialog modal-md">
                            <div className="modal-content">
                                <div className="modal-header d-flex justify-content-between  px-md-4">
                                    <h5 className="modal-title" id="modal_otp_Label">Education & Occupation Details</h5>
                                    <button type="button" className="btn-close" onClick={() => setIsActiveEducation(false)}></button>
                                </div>
                                <div className="modal-body p-md-4">
                                    <div className="tt_data  ">
                                        <div className="tt_card outline-success">
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Education: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.education}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Occupation: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.occupation}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Marital Status: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.marital_status}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Annual Income: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.annual_income}
                                                </span>
                                            </div>
                                            <div className="tt_item no-border">
                                                <span className="text-muted"> Annual Turnover: </span>
                                                <span className="tt_normal">
                                                    {userKycDetails?.otherDetails?.annual_turnover}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop fade show"> </div>
                </div >
            )}

        </main >
    );
};

export default LevelTwoKyc;
