import React, { useContext } from "react";
import { Routes, Route, useLocation } from 'react-router-dom';
import { ProfileContext } from "../ProfileProvider";
import ErrorPage from "../../Component/CoustamComponent/ErrorPage";
import MainPage from "../../Component/CoustamComponent/HomePage/MainPage";
import Header from "../../Component/CoustamComponent/Header";
import Login from "../../Component/AuthComponent/Login";
import Signup from "../../Component/AuthComponent/Signup";
import ForgotPassword from "../../Component/AuthComponent/ForgotPassword";
import ComingSoon from "../../Component/CoustamComponent/ComingSoon";
import DashboardModule from "../../Component/Dashboard/DashboardModule";
import BuySell from "../../Component/Pages/BuySell";
import MarketPage from "../../Component/Pages/MarketPage";
import PersonalInformation from "../../Component/Dashboard/Accounts/PersonalInformation";
import PersonalKyc from "../../Component/Dashboard/Accounts/KycManagements/PersonalKyc";
import SecurityModules from "../../Component/Dashboard/Accounts/SecurityModules";
import PaymentModules from "../../Component/Dashboard/Accounts/PaymentModules";
import ChangePassword from "../../Component/Dashboard/Accounts/ChangePassword";
import NotificationsPage from "../../Component/Dashboard/NotificationsPage";
import ExchangePage from "../../Component/Pages/ExchangePage";
import ReferralPage from "../../Component/Pages/ReferralPage";
import SpotOrders from "../../Component/Dashboard/Transactions/SpotOrders";
import TransactionHistory from "../../Component/Dashboard/Transactions/TransactionHistory";
import BuySellTranstions from "../../Component/Dashboard/Transactions/BuySellTranstions";
import RecentDevices from "../../Component/Dashboard/AccountActivity/RecentDevices";
import ReferrallistPage from "../../Component/Pages/ReferrallistPage";
import WalletPage from "../../Component/Dashboard/Assets/WalletPage";
import P2pPage from "../../Component/Dashboard/Assets/P2pPage";
import CurrencyManagement from "../../Component/Dashboard/Accounts/CurrencyManagement";
import DepositFunds from "../../Component/Dashboard/FundsManagement/DepositFunds";
import WithdrawFunds from "../../Component/Dashboard/FundsManagement/WithdrawFunds";
import Activities from "../../Component/Dashboard/AccountActivity/Activities";
import FeeTdsSummary from "../../Component/Dashboard/YourReports/FeeTdsSummary";
import AccountStatement from "../../Component/Dashboard/YourReports/AccountStatement";
import AddNominee from "../../Component/Dashboard/Accounts/AddNominee";
import ManagingFeatures from "../../Component/Dashboard/Accounts/ManagingFeatures";
import SupportSystem from "../../Component/Dashboard/SupportSystem";
import EntitySignup from "../../Component/AuthComponent/EntitySignup";
import SupportMessage from "../../Component/Dashboard/SupportMessage";
import CompanyManagement from "../../Component/CoustamComponent/FooterComponent/Aboutus/CompanyManagement";
import DownloadApp from "../../Component/CoustamComponent/FooterComponent/Aboutus/DownloadApp";
import Policies from "../../Component/CoustamComponent/FooterComponent/Policies";
import EntityKyc from "../../Component/Dashboard/Accounts/KycManagements/EntityKyc";
import UserSupport from "../../Component/CoustamComponent/FooterComponent/Contactus/UserSupport";
import PartnershipInquiry from "../../Component/CoustamComponent/FooterComponent/Contactus/PartnershipInquiry";
import GrievanceRedressalPrincipalOfficer from "../../Component/CoustamComponent/FooterComponent/Contactus/GrievanceRedressalPrincipalOfficer";
import ProductFeedbackSuggestions from "../../Component/CoustamComponent/FooterComponent/Contactus/ProductFeedbackSuggestions";
import LeaLegal from "../../Component/CoustamComponent/FooterComponent/Contactus/LeaLegal";
import FeeStructure from "../../Component/CoustamComponent/FooterComponent/Contactus/FeeStructure";
import ListingYourCoin from "../../Component/CoustamComponent/FooterComponent/ServicesFooter/ListingYourCoin";
import MarketMakerProgram from "../../Component/CoustamComponent/FooterComponent/ServicesFooter/MarketMakerProgram";
import PrivateRoute from "../PrivateRoute";
import NotProtectedRoute from "../NotProtectedRoute";
import CareerPage from "../../Component/CoustamComponent/FooterComponent/Aboutus/CareerPage";
import ScrollButton from "../ScrollButton";
import ChangeEmail from "../../Component/CoustamComponent/ChangeEmail";
import ChangePhoneNumber from "../../Component/CoustamComponent/ChangePhoneNumber";
import FundingWallet from "../../Component/Dashboard/Assets/FundingWallet";
import SpotWallet from "../../Component/Dashboard/Assets/SpotWallet";
import Futures from "../../Component/Dashboard/Assets/Futures";
import LevelTwoKyc from "../../Component/Dashboard/Accounts/KycManagements/LevelTwoKyc";

const Routing = () => {

  const location = useLocation();
  const { userDetails } = useContext(ProfileContext);
  const userType = userDetails?.user?.user_type
  const isChangeEmailPath = location.pathname === "/change-phone" || location.pathname === "/change-phone";

  return (
    <>
      {!isChangeEmailPath && <Header />}
      <Routes>

        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <DashboardModule />
            </PrivateRoute>
          }
        />

        <Route
          path="/wallet"
          element={
            <PrivateRoute>
              <WalletPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/funding_wallet"
          element={
            <PrivateRoute>
              <FundingWallet />
            </PrivateRoute>
          }
        />
        <Route
          path="/future_wallet"
          element={
            <PrivateRoute>
              <Futures />
            </PrivateRoute>
          }
        />

        <Route
          path="/spot_wallet"
          element={
            <PrivateRoute>
              <SpotWallet />
            </PrivateRoute>
          }
        />


        <Route
          path="/Peer_to_peer"
          element={
            <PrivateRoute>
              <P2pPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/buy_sell_transactions"
          element={
            <PrivateRoute>
              <BuySellTranstions />
            </PrivateRoute>
          }
        />

        <Route
          path="/spot_order"
          element={
            <PrivateRoute>
              <SpotOrders />
            </PrivateRoute>
          }
        />

        <Route
          path="/transaction_history"
          element={
            <PrivateRoute>
              <TransactionHistory />
            </PrivateRoute>
          }
        />

        <Route
          path="/personal_information"
          element={
            <PrivateRoute>
              <PersonalInformation />
            </PrivateRoute>
          }
        />

        <Route
          path="/managing_features"
          element={
            <PrivateRoute>
              <ManagingFeatures />
            </PrivateRoute>
          }
        />

        <Route
          path="/verify_kyc"
          element={
            <PrivateRoute>
              {userType === "Entity" ? (
                <EntityKyc />
              ) : userType === "Personal" ? (
                <PersonalKyc />
              ) : null}
            </PrivateRoute>
          }
        />


        <Route
          path="/upgrade_kyc"
          element={
            <PrivateRoute>
              <LevelTwoKyc />
            </PrivateRoute>
          }
        />


        <Route
          path="/payments"
          element={
            <PrivateRoute>
              <PaymentModules />
            </PrivateRoute>
          }
        />

        <Route
          path="/security"
          element={
            <PrivateRoute>
              <SecurityModules />
            </PrivateRoute>
          }
        />

        <Route
          path="/add_nominee"
          element={
            <PrivateRoute>
              <AddNominee />
            </PrivateRoute>
          }
        />

        <Route
          path="/currency_preference"
          element={
            <PrivateRoute>
              <CurrencyManagement />
            </PrivateRoute>
          }
        />

        <Route
          path="/change_password"
          element={
            <PrivateRoute>
              <ChangePassword />
            </PrivateRoute>
          }
        />

        <Route
          path="/recent_devices"
          element={
            <PrivateRoute>
              <RecentDevices />
            </PrivateRoute>
          }
        />

        <Route
          path="/activities"
          element={
            <PrivateRoute>
              <Activities />
            </PrivateRoute>
          }
        />

        <Route
          path="/account_summary"
          element={
            <PrivateRoute>
              <FeeTdsSummary />
            </PrivateRoute>
          }
        />

        <Route
          path="/account_statement"
          element={
            <PrivateRoute>
              <AccountStatement />
            </PrivateRoute>
          }
        />

        <Route
          path="/referral"
          element={
            <PrivateRoute>
              <ReferralPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/referral-list"
          element={
            <PrivateRoute>
              <ReferrallistPage />
            </PrivateRoute>
          }
        />


        <Route
          path="/all_notificatios"
          element={
            <PrivateRoute>
              <NotificationsPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/support"
          element={
            <PrivateRoute>
              <SupportSystem />
            </PrivateRoute>
          }
        />

        <Route
          path="/support-messages"
          element={
            <PrivateRoute>
              <SupportMessage />
            </PrivateRoute>
          }
        />

        <Route
          path="/deposit_funds"
          element={
            <PrivateRoute>
              <DepositFunds />
            </PrivateRoute>
          }
        />


        <Route
          path="/withdraw_funds"
          element={
            <PrivateRoute>
              <WithdrawFunds />
            </PrivateRoute>
          }
        />

        <Route
          path="/login"
          element={
            <NotProtectedRoute>
              <Login />
            </NotProtectedRoute>
          }
        />

        <Route
          path="/signup"
          element={
            <NotProtectedRoute>
              <Signup />
            </NotProtectedRoute>
          }
        />

        <Route
          path="/forgot_password"
          element={
            <NotProtectedRoute>
              <ForgotPassword />
            </NotProtectedRoute>
          }
        />

        <Route
          path="/entity-signup"
          element={
            <NotProtectedRoute>
              <EntitySignup />
            </NotProtectedRoute>
          }
        />

        <Route exact path="/" element={<MainPage />} />
        <Route exact path="/buy_sell" element={<BuySell />} />
        <Route exact path="/markets" element={<MarketPage />} />
        <Route exact path="/exchange/:pairName" element={<ExchangePage />} />
        <Route exact path="/coming_soon" element={<ComingSoon />} />
        <Route exact path="/company_management" element={<CompanyManagement />} />
        <Route exact path="/user_support" element={<UserSupport />} />
        <Route exact path="/partnership_inquiry" element={<PartnershipInquiry />} />
        <Route exact path="/grievance_redressal_principal_officer" element={<GrievanceRedressalPrincipalOfficer />} />
        <Route exact path="/product_feedback_suggestions" element={<ProductFeedbackSuggestions />} />
        <Route exact path="/fees_structure" element={<FeeStructure />} />
        <Route exact path="/lea_legal" element={<LeaLegal />} />
        <Route exact path="/coin_list" element={<ListingYourCoin />} />
        <Route exact path="/market_maker_program" element={<MarketMakerProgram />} />
        <Route exact path="/download-app" element={<DownloadApp />} />
        <Route exact path="/policies" element={<Policies />} />
        <Route exact path="/career" element={<CareerPage />} />
        <Route exact path="/change-email" element={<ChangeEmail />} />
        <Route exact path="/change-phone" element={<ChangePhoneNumber />} />
        <Route path="/*" element={<ErrorPage />} />

      </Routes>
      <ScrollButton />

    </>
  );
};

export default Routing;
