const appUrl = "https://dev.api.cryptoindia.in";

export const weburl = `${window.origin}/`

export const ApiConfig = {

  register: "registration",
  check_attempt: "check-attempt",
  valid_type:"valid-type",
  getOtp: "send-otp",
  signIn: "login",
  verify_otp: "verify-otp",
  forgotPassword: "forgot-passowrd",
  userDetails: "details",
  editProfile: "edit-profile",
  change_password: "change-passowrd",
  genrate_google_qr: "generate-google-qr",
  buySell: 'quick-buy-sell',
  cancel_qbs_order: "cancel-qbs-order",
  walletby_coinName: "wallet-by-coinname",
  getWallet: "wallets",
  identityKyc: "submit-kyc",
  coin_details: "get-coin-details",
  favoriteList: 'favorite-list',
  getpairs: 'pair-list',
  placeOrder: 'place-order',
  pastOrder: 'past-order',
  cancelOrder: 'cancel-order',
  getHistoricalData: 'historical-data',
  favoriteCoin: 'favorite-coin',
  edit_currency: "currency-preference",
  depositAddress: "generate-address",
  verify_deposit: "verify-deposit",
  crypto_ithdrawal: "crypto-withdrawal",
  update2fa: "enable-2fa",
  update_ticket: "submit-ticket",
  add_nominee: 'add-nominee',
  add_bank: "add-bank",
  // upload_bank_document:"upload-bank-document",
  // edit_bank: "update-bank-details",
  verify_Upi: "verify-upi-details",
  // edit_upi: "update-upi-details",
  delete_upi: "delete-upi-details",
  delete_bank: "delete-bank-details",
  default_bank: "update-bank-status",
  default_upi: "set-default-upi",
  cancel_all_order: "cancel-all-orders",
  update_permission: "update-permissions",
  delete_ticket: "delete-ticket",
  notifications: "notifications",
  user_referral_list: "user-referral-list",
  ticket_user_reply: "ticket-user-reply",
  single_ticket: "user-single-ticket",
  admin_bank_details: "admin-bank-details",
  admin_upi_details: "admin-upi-details",
  qbs_list: "admin-qbs-list-details",
  utr_trans: "add-utr-number",
  kyc_details: "kyc-details",



  fetch_kyc_step: "fetch-kyc-step",
  update_kyc_step: "update-kyc-step",
  verify_pan: "verify-pan",
  user_aml_check: "user-aml-check",
  generate_adhaar_otp: "generate-adhaar-otp",
  adhaar_details: "adhaar-details",
  // dl_details: "dl-details",
  // passport_details: 'passport-details',
  check_user_liveness: "check-user-liveness",
  user_face_match: "user-face-match",
  other_kyc_details: "other-kyc-details",
  upload_documents: "upload-documents",
  kyc_declaration: "kyc-declaration",


  entity_pan_details: "verify-pan",
  entityAmlData: "entityAmlData",
  cinDetails: "cinDetails",
  entity_details: "entityDetails",

  saveUboData: "saveUboData",
  main_trader: "mainTrader",
  verificationdocuments: "verificationdocuments",

  company_kyc_details: "completeCompanyDetails",

  download_report: "download-report",
  getWalletDetails: "getWalletDetails",
  hyperverge_liveness_result: "hyperverge-liveness-result",

  verify_director_pan:"verify-director-pan",

  jobApplication: "job-application",
  job_openings: "job-openings",
  qbs_fees: "qbs-fees",
  spot_fees: "spot-fees",
  market_maker: "market-maker",
  coin_list: "coin-list",
  partnership:"partnership",
  support_ticket:"support-ticket",
  feedback:"feedback",
  support_ticket_details:"support-ticket-details",
  progress_bar:"progress-bar",
  user_update_ticket:"user-update-ticket",
  hyperverge_sdklogin:"third-party-test",
  rejected_kyc_remarks:"rejected-kyc-remarks",
  currency_list:"currency-list",
  latest_pan:"latest-pan",
  // ============URLs================

  baseUser: `${appUrl}/v1/user/`,
  baseqbs: `${appUrl}/v1/qbs/`,
  baseWallet: `${appUrl}/v1/wallet/`,
  baseCoin: `${appUrl}/v1/coin/`,
  baseExchange: `${appUrl}/v1/exchange/`,
  baseAddress: `${appUrl}/v1/address/`,
  baseEntity: `${appUrl}/v1/user/entity/`,

  baseUrl: `${appUrl}/`,

  webSocketUrl: "wss://dev.api.cryptoindia.in",
};
